import React from 'react';
import './Ultranaut.css';
import Home from './home';

const Ultranaut = () => {
  return (
    <div className="ultranaut">
      <Home />
    </div>
  );
};

export default Ultranaut;
